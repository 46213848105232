<template>
	<section id="participant" class="pb-3">
		<b-row>
		    <b-col md="12">

		    	<b-card bg-variant="light">
			        <b-img center src="@/assets/images/itees/participant-portal/Welcome.png" fluid alt=""></b-img>
			   	</b-card>
		    	
		    </b-col>
		</b-row>		
		<b-row>
	      	<b-col sm="12" md="6" class="mb-md-0 mb-3">
		        <b-card
			          :img-src="require('@/assets/images/itees/participant-portal/My-Activities.jpg')"
			          img-top
			          img-alt="My Activities"
			          title="My Activities"
			          class="mb-0 h-100 check-box"
			        >
		          	<b-card-text>
		            	Explore all the activities you have installed for you!
		          	</b-card-text>
		          	<template v-if="attendanceCheck.timeline_checkin != null">
		          		<b-button
					      	variant="primary"
					      	@click="activityCheckinClick">
					      	<feather-icon
					            icon="CheckCircleIcon"
					            size="24"
					        /> Check-in
					    </b-button>
		          	</template>
		          	<template v-else>
		          		<b-button
					      	variant="primary"
					      	style="background: #B1B1B1 !important; border: none"
					      	disabled >
					      	<feather-icon
					            icon="CheckCircleIcon"
					            size="24"
					        /> Check-in

					    </b-button>
		          	</template>
		        </b-card>
	      	</b-col>
	      	<b-col sm="12" md="6" class="mb-md-0 mb-3">
        		<template v-if="attendanceCheck.evaluation != null">
        			<b-card
			          :img-src="require('@/assets/images/itees/participant-portal/Participant-Training-Feedback.jpg')"
			          img-top
			          img-alt="Participant Training Feedback"
			          title="Participant Training Feedback"
			          class="mb-0 h-100 cursor-pointer"
			          @click="activityEvaClick"
			        >
			          <b-card-text>
			            Remember to evaluate your activities once it is completed!
			          </b-card-text>
			        </b-card>
        		</template>			
        		<template v-else>
        			<b-card
			          :img-src="require('@/assets/images/itees/participant-portal/Participant-Training-Feedback.jpg')"
			          img-top
			          img-alt="Participant Training Feedback (Disabled)"
			          title="Participant Training Feedback (Disabled)"
			          class="mb-0 h-100"
			        >
			          <b-card-text>
			            No Active Activity!
			          </b-card-text>
			        </b-card>
        		</template>        
	      	</b-col>
	    </b-row>
	</section>	
 
</template>

<script>

	export default {
	  	data() {
		    return {
		    	attendanceCheck: {},
		    	att_id: null,
		    	eva_id: null,
		    }
		},
		created(){
			this.CheckinAction()
		},
		methods: {
			CheckinAction(){
				this.$store
				.dispatch('project/timelineCheckinDashboard', {})
				.then(response => {
					this.attendanceCheck = response.data.data
					if(this.attendanceCheck && this.attendanceCheck.timeline_checkin ){
						this.att_id = this.attendanceCheck.timeline_checkin.activity_id
					}
					if(this.attendanceCheck && this.attendanceCheck.evaluation ){
						this.eva_id = this.attendanceCheck.evaluation.activity_id
					}
					// console.log('this.attendanceCheck: ', this.attendanceCheck)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			activityCheckinClick(){
				this.$router.push({ 
					path: `/participant/my-activities/${this.att_id }`, 
					query: { tab: 2, checkIn: true },
				})
			},
			activityEvaClick(){
				this.$router.push({ path: `/participant/my-activities/${this.eva_id}`, query: { tab: 3 } })
			},
		}
	};	
	
</script>

<style lang="scss">
	.size-18{
		font-size: 18px; 
	}
	.card-title{
		font-size:16px !important;
		margin-bottom: 20px !important;
	}
	.check-box .card-body h4, .check-box .card-body .card-text {
	    width: 70%;
	}
	.check-box .card-body{
		position: relative;
	}
	.check-box .card-body button.btn.btn-primary {
	    font-size: 24px;
	    max-width: 164px;
	    width: 100%;
	    padding: 0;
	    height: 50px;
	}
	.check-box .card-body button svg{
		margin-right:10px
	}
	@media screen and (max-width: 1440px){
		.check-box .card-body h4, .check-box .card-body .card-text {
		    width: 65%;
		}
	}
	@media screen and (max-width: 1400px) {		
		.check-box .card-body button.btn.btn-primary{
			font-size: 18px;
			width: 125px;
			height: 40px;
		}
		.check-box .card-body button svg{
			margin-right: 5px;
		    width: 18px;
		    height: 18px;
		}
	}
	@media screen and (max-width: 921px) {
		.check-box .card-body h4, .check-box .card-body .card-text {
		    width: 65%;
		}
	}
	@media screen and (min-width:767px) and (max-width: 800px) {
		.check-box .card-body h4, .check-box .card-body .card-text {
		    width: 55%;
		}
	}
	@media screen and (min-width: 481px){
		.check-box .card-body button.btn.btn-primary {
		    position: absolute;
		    right: 21px;
		    top: 50%;
		    transform: translateY(-50%);
		}
	}
	@media screen and (max-width: 480px) {
		.check-box .card-body button.btn.btn-primary {
		    font-size: 16px;
		    width: 110px;
		    height: 33px;
		}
		.check-box .card-body button svg {
		    width: 16px;
		    height: 16px;
		    margin-right: 3px;
		}
		.card-title{
			margin-bottom: 12px !important;
		}
		.check-box .card-body h4, .check-box .card-body .card-text {
		    width: 100%;
		}
	}
</style>

